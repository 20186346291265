<template>
  <!-- <LoginInstituteIcon /> -->
  <v-container fill-height fluid v-if="!isCheckingWhitelabel">
    <div class="top-right-logo">
      <img src="../../assets/only_logo.png" alt="Logo" />
    </div>
    <v-row justify="center" align="center">
      <v-col cols="12" md="5" align-self="center">
        <v-card class="text-center px-2 py-4 mt-4">
          <svg-asset width="350px" name="institution-login" />
          <!-- <v-form>
                <v-text-field solo rounded>

                </v-text-field>
            </v-form> -->
          <h2>Enter School Code</h2>
          <p>Please, enter the code given to you by your school</p>
          <v-form class="px-2" @submit.prevent="checkCodeAndContinue">
            <v-text-field
              class="mb-4"
              solo
              prepend-inner-icon="mdi-pencil"
              rounded
              :error-messages="formErrors.codeError"
              inputmode="numeric"
              @input="formErrors.codeError = null"
              v-model="code"
              hide-details="auto"
            >
              <template v-slot:label>
                <div class="ml-2">Enter Code</div>
              </template>
            </v-text-field>
            <v-btn
              :disabled="isLoading"
              large
              color="primary"
              block
              type="submit"
              >Submit
            </v-btn>
            <!-- Disabled for intract app -->
            <v-btn
              :disabled="isLoading"
              large
              class="mt-3"
              color="primary"
              outlined
              block
              @click="goToRegister"
              >Register</v-btn
            >
          </v-form>
          <div style="margin-top: 12px">
            <a
              href="https://youtu.be/ecwxXJRNGaU"
              target="_blank"
              class="ma-0 text-caption"
              >How to Login?</a
            >
          </div>
          <div style="margin-top: 5px">
            <a
              href="https://youtu.be/Y0A-_rzzQFU"
              target="_blank"
              class="ma-0 text-caption"
              >How to Register?</a
            >
          </div>
          <!-- <p class="mb-0 mt-2 body-2 text-decoration-underline"><a>Create your institution on Intract</a></p> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SvgAsset from "@assets/images/SvgAsset.vue";
import { mapActions, mapGetters } from "vuex";
import endpoints from "@api/repository";
import { App } from "@capacitor/app";
import api from "@api/index";
import Helper from '@utils/misc';
export default {
  name: "LoginInstitute",
  components: {
    SvgAsset,
    // LoginInstituteIcon
  },
  data() {
    return {
      code: "",
      formErrors: {
        genError: null,
        codeError: null,
      },
      snackbar: {
        visible: false,
        text: "",
      },
      isCheckingWhitelabel: true, // true by default, turned to false after created function
    };
  },
  computed: {
    ...mapGetters(["isLoading", "currentUser", "currentInstitution"]),
    essentials() {
      return {
        accessToken: "",
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
  },
  methods: {
    ...mapActions(["setLoading", "showSnackbar"]),

    // checks for any invalid or empty field
    validateFields() {
      this.code = this.code.trim();
      this.formErrors.codeError = null;
      if (!this.code) {
        this.formErrors.codeError = "This field is required";
        return false;
      }
      return true;
    },

    async goToRegister(){
      this.$router.push({
        name: "RegisterUser",
      });
    },

    async checkCodeAndContinue() {
      if (!this.validateFields()) return false;

      // send request to server if code not empty
      var url = endpoints.checkInstitutionCode + this.code + "/";
      var response = await api.call(this.essentials, url);
      if (response) {
        this.$router.replace({
          name: "LoginUser",
          params: { code: this.code },
        });
        return;
      }
      this.formErrors.codeError = "Invalid Code";
    },

    async getInstitutionDetailsByBundleId(bundleId) {
      if (bundleId == "com.intract.nns") bundleId = "com.intract.ifi_nns"; // only valid for NNS (IOS) <- not to be used again
      var url = endpoints.getInstitutionDetailsByBundleId + bundleId + "/";
      var response = await api.call(this.essentials, url, api.Methods.GET);
      if (response.code) {
        this.$router.replace({
          name: "LoginUser",
          params: { code: response.code },
        });
      }
    },

    handleApiError(err) {
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        console.log(err.response);
      }
    },
  },
  async created() {
    // if user on login page and currentUser != null, navigate to home '/'
    // this.setLoading(false);
    if (this.currentUser != null && this.currentInstitution != null)
      this.$router.push("/");
    localStorage.clear();

    // check if whitelabelled app
    if ((await Helper.getDevicePlatform()) != "web") {
      var appInfo = await App.getInfo();
      if (appInfo && appInfo.id != "com.intract.kraf")
        this.getInstitutionDetailsByBundleId(appInfo.id);
    }

    this.isCheckingWhitelabel = false;
    // this.$router.push({''})
  },
};
</script>
<style  scoped>
.top-right-logo {
  display: flex;
  position: fixed;
  top: 20px;
  right: 10px;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  justify-content: center;
  align-items: center;
}

.top-right-logo > img {
  max-height: 60%;
  max-width: 60%;
}
</style>